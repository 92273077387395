import React from "react";
import { Link } from "gatsby";
import { Transition, TransitionGroup } from "react-transition-group";

import "./main.css";
import "./font.css";

import { FaGithub, FaLinkedin, FaTwitter, FaUser } from "react-icons/fa";

function getPostFileName(str) {
  if (str.length > 12) {
    return str.substring(0, 12) + "*";
  }
  return str;
}

function Layout({ children, location }) {
  return (
    <div className="wrapper page">
      <div className="header-box">
        <div className="author-box"
             style={{ flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <Link to="/">
              <img className="top-avatar" alt="tu-huynh"
                   src="/tu-huynh.jpg"
                   style={{ width: "100px" }}/>
            </Link>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '2.5px' }}>
              <div style={{ fontFamily: `SUNRIZE, "Noto Sans", sans-serif`,
                fontSize: '18px', backgroundColor: 'black', color: 'white', padding: '4px', fontWeight: 'bold' }}>tuhuynh</div>
              <div>.com</div>
            </div>
          </div>
          <div className="info" style={{ border: '1px solid black' }}>
            <div>
              <ul>
                <li>
                  <Link to="/about" title="About Me">
                    <FaUser />
                  </Link>
                </li>
                <li>
                  <a href="https://twitter.com/tuhuynh27" target="_blank" rel="noopener noreferrer">
                    <FaTwitter/>
                  </a>
                </li>
                <li className="github">
                  <a href="https://github.com/tuhuynh27" target="_blank" rel="noopener noreferrer">
                    <FaGithub/>
                  </a>
                </li>
                <li className="linkedin">
                  <a href="https://www.linkedin.com/in/tuhuynh27" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin/>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <TransitionGroup component={null}>
        <Transition key={location.pathname} exit={false} timeout={100}>
          {status => (
            <div className={`content-box clearfix page ${status}`}>{children}</div>
            )}
        </Transition>
      </TransitionGroup>
      <div className="footer">
        <p>2025 &copy; by <span style={{ fontFamily: `SUNRIZE, "Noto Sans", sans-serif` }}>Tu Huynh</span></p>
        <p>
          <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/">
            <img alt="Creative Commons License" src="https://i.creativecommons.org/l/by-nc-nd/4.0/80x15.png"/>
          </a>
        </p>
      </div>
    </div>
  );
}

export default React.memo(Layout);
